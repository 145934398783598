<template>
  <div
    :style="{ backgroundColor: node.plataforma_mae == '0' ? '#efefef' : '', borderRadius: '2em'}"
  >
    <div
      :fast-nome-plataforma="node.nome_plataforma"
      :fast-plataforma-mae="node.plataforma_mae"
      :fast-id-plataforma="node.id_plataforma"
      class="fd-app-hierarquia-plataformas-list w-100 mb-2"
    >
      <span class="text-secondary">{{ node.plataforma_mae == "0" ? "Plataforma mãe" : "Filiada" }}</span>
      <div class="fd-app-hierarquia-plataformas-list-item">
        <div class="fd-app-hierarquia-plataformas-list-item-logo">
          <img
            v-if="node.logo_plataforma"
            :src="ajustaLinkApiAntiga(node.logo_plataforma)"
          >
          <img
            v-else
            :src="imagelogoPlataformaDefault"
          >
        </div>
        <div class="fd-app-hierarquia-plataformas-list-item-info">
          <strong>{{ node.nome_plataforma }}</strong>
          <p class="mb-0">
            <span class="text-secondary">Data de criação: &nbsp;</span>
            <span
              v-if="node.data_cadastro_plataforma"
            >{{ node.data_cadastro_plataforma.split("T")[0].split("-")[2] + "/" + node.data_cadastro_plataforma.split("T")[0].split("-")[1] + "/" + node.data_cadastro_plataforma.split("T")[0].split("-")[0] }}</span>
            <span
              v-else
              class="text-danger"
            >Não registrado</span>
          </p>
          <p class="mb-0 d-flex align-items-center">
            <span class="text-secondary">Nível de acesso: &nbsp;</span>
            <a
              v-if="node.administrador == 'S' && node.ativo == 'S' && node.id_usuario && (node.status == 'A' || node.status == 'T')"
              :href="'/plataforma/' + node.id_plataforma + '/admin'"
              class="btn btn-sm btn-success pt-0 pb-0 ml-1"
            >
              <small class="text-white">Administrador</small>
            </a>
            <span
              v-else-if="node.professor == 'S' && node.ativo == 'S' && node.id_usuario && (node.status == 'A' || node.status == 'T')"
              class="text-success"
            >Professor</span>
            <a
              v-else-if="node.id_usuario && node.ativo == 'S' && (node.status == 'A' || node.status == 'T')"
              :href="'/plataforma/' + node.id_plataforma + '/aluno'"
              class="btn btn-sm btn-success pt-0 pb-0 ml-1"
            >
              <small class="text-white">Aluno</small>
            </a>
            <span
              v-else
              class="text-danger"
            >Não cadastrado na plataforma</span>
          </p>
          <p class="mb-0">
            <span class="text-secondary">Status: &nbsp;</span>
            <span
              v-if="node.ativo == 'S' && (node.status == 'A' || node.status == 'T')"
              class="text-success"
            >Ativo</span>
            <span
              v-else
              class="text-danger"
            >Inativo</span>
          </p>
          <p
            v-if="node.plataforma_representante == 'S'"
            class="mb-0"
          >
            <span class="text-success">Plataforma representante</span>
          </p>
          <p
            v-if="node.plataforma_venda == 'S'"
            class="mb-0"
          >
            <span class="text-success">Plataforma de venda</span>
          </p>
          <p
            v-if="node.token"
            class="mb-0"
          >
            <span class="text-danger">Token não validado</span>
          </p>
          <div
            v-if="$store.state.fastUsuarioAdminFast && node.id_plataforma != 1287"
            class="row"
          >
            <div class="col-12">
              <div class="fast-item-plataforma mb-4 mt-4">
                <button
                  v-if="node.administrador != 'S'"
                  class="btn btn-sm btn-primary pt-0 pb-0 mr-1"
                  @click="$store.state.fastPlataformaInserirAdmin = node"
                >
                  <small>
                    <b-icon-person-plus-fill /> Virar admin
                  </small>
                </button>
                <button
                  v-else-if="node.ativo != 'S'"
                  class="btn btn-sm btn-primary pt-0 pb-0 mr-1"
                  @click="$store.state.fastPlataformaInserirAdmin = node"
                >
                  <small>
                    <b-icon-person-plus-fill /> Ativar admin
                  </small>
                </button>
                <button
                  v-if="node.status == 'A' || node.status == 'T'"
                  class="btn btn-sm btn-danger pt-0 pb-0 mr-1"
                  @click="$store.state.fastPlataformaDesativar = node"
                >
                  <small>
                    <i class="fas fa-minus-circle" /> Desativar
                  </small>
                </button>
                <button
                  v-else
                  class="btn btn-sm btn-success pt-0 pb-0 mr-1"
                  @click="$store.state.fastPlataformaReativar = node"
                >
                  <small>
                    <i class="fas fa-plus-circle" /> Reativar
                  </small>
                </button>
                <button
                  class="btn btn-sm btn-danger pt-0 pb-0 mr-1"
                  @click="$store.state.fastPlataformaExcluir = node"
                >
                  <small>
                    <i class="fas fa-trash-alt" /> Excluir
                  </small>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="fd-app-hierarquia-plataformas-list-item-actions">
          <div class="fd-app-hierarquia-plataformas-list-item-actions-statistic">
            <ul class="fd-app-hierarquia-plataformas-list-item-actions-statistic-list">
              <li class="fd-app-hierarquia-plataformas-list-item-actions-statistic-list-item">
                <img v-lazy="imageCoursesIcon">
                <span class="ml-2 text-secondary">{{ node.total_cursos_publicados }}</span>
                curso(s)
              </li>
              <li class="fd-app-hierarquia-plataformas-list-item-actions-statistic-list-item">
                <img v-lazy="imageUsersIcon">
                <span class="ml-2 text-secondary">{{ node.total_usuarios }}</span>
                usuário(s)
              </li>
            </ul>
          </div>
          <div
            class="fd-app-hierarquia-plataformas-list-item-actions-cta d-flex justify-content-end"
          >
            <div class="fast-item-plataforma mb-4">
              <div class="fast-item-plataforma mb-4">
                <a
                  v-if="node.ativo == 'S' && node.id_usuario && (node.status == 'A' || node.status == 'T')"
                  :href="'plataforma/' + node.id_plataforma"
                  class="btn btn-primary"
                  @click.prevent="irParaPlataforma(node.id_plataforma)"
                >Ir para plataforma</a>
              </div>
            </div>

            <!-- Se possuir filhos -->
            <a
              v-if="node.nodes"
              class="ml-3 mt-4 collapsed"
              fd-data-toggle="collapse"
              href="#"
              role="button"
              aria-expanded="false"
              @click="eventoCollapse($event, node.id_plataforma)"
            >
              <img v-lazy="imageSiteCaretDownArrow">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="node.nodes"
      class="fd-app-hierarquia-plataformas-collapse w-100"
      :fast-plataforma-filhos="node.id_plataforma"
    >
      <node
        v-for="plataforma in node.nodes"
        :key="plataforma.id_plataforma"
        :node="plataforma"
      />
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageSiteCaretDownArrow from "@/assets/images/caret-down-arrow.svg";
import imageUsersIcon from "@/assets/images/users-icon.svg";
import imageCoursesIcon from "@/assets/images/courses-icon.svg";
import imagelogoPlataformaDefault from "@/assets/images/app/plataforma-img-02.jpg";

export default {
  name: "Node",
  mixins: [methods],
  props: {
    node: {
      type: [Object, Array],
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      imageSiteCaretDownArrow,
      imageUsersIcon,
      imageCoursesIcon,
      imagelogoPlataformaDefault,
      fastPlataformaEditar: {},
    };
  },
  created() {},
  mounted: function () {},
  updated() {},
  methods: {
    eventoCollapse(event, id_plataforma) {
      event.preventDefault();
      event.target.classList.toggle("collapsed");
      document
        .querySelector('[fast-plataforma-filhos="' + id_plataforma + '"]')
        .classList.toggle("collapse");
    },
    irParaPlataforma(id_plataforma){
      if (this.getFastSessao(settings.fastSessaoPlataforma)) {
        if (this.getFastSessao(settings.fastSessaoPlataforma).id_plataforma != id_plataforma) {
          this.setFastSessao(settings.fastSessaoPlataforma, null);
          this.setFastSessao(settings.fastSessaoPermissoes, null);
          this.setFastSessao(settings.fastSessaoPlataformaModulos, null);
          this.$store.state.fastPermissoes.administrador = "N"
          this.$store.state.fastPermissoes.professor = "N"
          this.$store.state.fastPermissoes.aluno = "N"
          this.$store.state.fastPermissoes.secretario = "N"
          this.$store.state.fastPermissoes.tesouraria = "N"
          this.$store.state.fastPermissoes.responsavel = "N"
          this.$store.state.fastPlataformaModulos.modulo_secretaria = false
        }
      }      

      this.$router.push('/plataforma/' + id_plataforma);
    }
  },
};
</script>
<style scrope>
</style>
